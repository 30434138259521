import formatDateFn from 'date-fns/format'
import { defaultPageSize } from '../components/Pagination/Pagination'
import { YYYY_MM_DD } from '../constants'
import { pushTagEvent } from '../tagManager/tagManager'

const createEndpoints = (apiStore) => ({
  user: {
    getUserinfo: (options) => apiStore.getRequest('/api/userinfo', options),
    getProfile: (options) => apiStore.getRequest('/api/profile', options),
    getUserGroups: (options) =>
      apiStore.getRequest('/api/rest/v1/etb/userinfo/groups', options),
    getUserinfoProfile: (options) =>
      apiStore.getRequest('/api/rest/v1/etb/userinfo/profile', options),
    verifyEmailAddress: (emailAddress, token, options) => {
      const payload = { emailAddress, token: token }

      return apiStore.postRequest(
        '/api/rest/v1/etb/verify-email-address',
        payload,
        options
      )
    },
    getPasswordRequirements: (options) =>
      apiStore.getRequest(
        '/api/rest/v1/etb/userinfo/passwordRequirements',
        options
      ),
    updateProfile: (payload, options) =>
      apiStore.patchRequest('/api/rest/v1/etb/userinfo', payload, options),
    verifyChanges: (payload, options) =>
      apiStore.postRequest(
        '/api/rest/v1/etb/userinfo/verifyChanges',
        payload,
        options
      ),
    setEmailAsPrimary: (email, options) =>
      apiStore.postRequest(
        '/api/rest/v1/etb/userinfo/email/setPrimary',
        { email },
        options
      ),
    editEmail: (oldEmail, newEmail, options) =>
      apiStore.putRequest(
        '/api/rest/v1/etb/userinfo/email/edit',
        { oldEmail, newEmail },
        options
      ),
    removeEmail: (email, options) =>
      apiStore.deleteRequest(
        '/api/rest/v1/etb/userinfo/email/delete',
        { email },
        options
      ),
    setPhoneNumberAsPrimary: (phoneNumber, options) =>
      apiStore.postRequest(
        '/api/rest/v1/etb/userinfo/phoneNumber/setPrimary',
        { phoneNumber },
        options
      ),
    editPhoneNumber: (oldPhoneNumber, newPhoneNumber, options) =>
      apiStore.putRequest(
        '/api/rest/v1/etb/userinfo/phoneNumber/edit',
        { oldPhoneNumber, newPhoneNumber },
        options
      ),
    removePhoneNumber: (phoneNumber, options) =>
      apiStore.deleteRequest(
        '/api/rest/v1/etb/userinfo/phoneNumber/delete',
        { phoneNumber },
        options
      ),
  },
  invite: {
    getExistingUsers: (companyId, payload, options) => {
      return apiStore.postRequest(
        `/api/rest/v1/etb/company/${companyId}/beneficiary/emailStatus`,
        payload,
        options
      )
    },
    getExistingEmployeeIds: (companyId, payload, options) => {
      return apiStore.postRequest(
        `/api/rest/v1/etb/company/${companyId}/beneficiary/empidStatus`,
        payload,
        options
      )
    },
    getInviteById: (inviteId, options) =>
      apiStore.getRequest(`/api/rest/v1/etb/invite/${inviteId}`, options),
    getCompanyAdminInvites: (companyId, options) => {
      const filter = `role eq "ADMIN" and companyId eq "${companyId}" and not (revoked pr or accepted pr)`

      const urlSearchParams = new URLSearchParams({ filter })

      return apiStore.getRequest(
        `/api/rest/v1/etb/invite?${urlSearchParams}`,
        options
      )
    },
    getInvitesByCompanyId: (companyId, params, options) => {
      const { startIndex, count, expired, role, benefitGroup, searchText } =
        params

      //expired and role are required params
      const urlSearchParams = new URLSearchParams({
        expired,
        role,
      })

      if (startIndex) {
        urlSearchParams.append('startIndex', startIndex)
      }

      if (count) {
        urlSearchParams.append('count', count)
      }

      if (benefitGroup) {
        urlSearchParams.append('benefitGroup', benefitGroup)
      }

      if (searchText) {
        urlSearchParams.append('searchText', searchText)
      }

      return apiStore.getRequest(
        `/api/rest/v1/etb/invite/${companyId}/invites?${urlSearchParams}`,
        options
      )
    },
    revokeInvitesByCompanyId: ({ companyId, payload, options }) => {
      return apiStore.postRequest(
        `/api/rest/v1/etb/invite/${companyId}/invites/batchRevoke`,
        payload,
        options
      )
    },
    resendInvitesByCompanyId: ({ companyId, payload, options }) => {
      return apiStore.postRequest(
        `/api/rest/v1/etb/invite/${companyId}/invites/batchResend?`,
        payload,
        options
      )
    },
    validateBeneficiaryInvite: (inviteId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/invite/${inviteId}/isValid?role=BENEFICIARY`,
        options
      ),
    validateCompanyAdminInvite: (inviteId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/invite/${inviteId}/isValid?role=ADMIN`,
        options
      ),
    acceptInvite: (inviteId, payload, options) =>
      apiStore.postRequest(
        `/api/rest/v1/etb/invite/${inviteId}/accept`,
        payload,
        options
      ),
    sendBeneficiaryInvites: (companyId, payload, options) =>
      apiStore.postRequest(
        `/api/rest/v1/etb/company/${companyId}/beneficiary/invite`,

        payload,
        options
      ),
    uploadBeneficiaryInvitees: (companyId, file, csvDelimiter, options) => {
      const payload = new FormData()

      payload.append('file', file)

      return apiStore.uploadRequest(
        `/api/rest/v1/etb/company/${companyId}/beneficiary/upload-invitees?csvDelimiter=${csvDelimiter}`,
        payload,
        options
      )
    },
    batchResend: (payload, options) =>
      apiStore.postRequest(
        '/api/rest/v1/etb/invite/batchResend',
        payload,
        options
      ),
    sendCompanyAdminInvites: (companyId, payload, options) =>
      apiStore.postRequest(
        `/api/rest/v1/etb/company/${companyId}/admin/invite`,
        payload,
        options
      ),
  },
  company: {
    getCompanies: ({
      startIndex,
      sortBy,
      sortOrder,
      searchText,
      statuses,
      options,
    }) => {
      const extractedStatuses = statuses.map((status) => status.value)
      const urlSearchParams = new URLSearchParams({
        // Pagination
        startIndex,
        count: defaultPageSize,

        // Sorting
        ...(sortBy && sortOrder
          ? {
              sortBy,
              sortOrder,
            }
          : {}),

        // Search
        ...(searchText ? { searchText } : {}),

        //purchaseState
        ...(extractedStatuses.includes('PURCHASE_ACTIVE')
          ? { purchaseState: true }
          : {}),
        ...(extractedStatuses.includes('PURCHASE_SUSPENDED')
          ? { purchaseState: false }
          : {}),
        //tosAccepted
        ...(extractedStatuses.includes('TOS_ACCEPTED')
          ? { tosAccepted: true }
          : {}),
        ...(extractedStatuses.includes('TOS_NOT_ACCEPTED')
          ? { tosAccepted: false }
          : {}),
        //accountActive
        ...(extractedStatuses.includes('ACCOUNT_ACTIVE')
          ? { accountActive: true }
          : {}),
        ...(extractedStatuses.includes('ACCOUNT_CLOSED')
          ? { accountActive: false }
          : {}),
      })

      return apiStore.getRequest(
        `/api/rest/v1/etb/company?${urlSearchParams.toString()}`,
        options
      )
    },
    getMyCompanies: (options) =>
      apiStore.getRequest('/api/rest/v1/etb/company', options),
    getCompanyById: (companyId, options) =>
      apiStore.getRequest(`/api/rest/v1/etb/company/${companyId}`, options),
    getConfirmedAdmins: (companyId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/company/${companyId}/admin`,
        options
      ),
    getCompanyTags: (companyId, options) =>
      apiStore.getRequest(`/api/rest/v1/etb/company/${companyId}/tag`, options),
    updateCompany: (companyId, payload, options) =>
      apiStore.putRequest(
        `/api/rest/v1/etb/company/${companyId}`,
        payload,
        options
      ),
    createCompany: (payload, options) =>
      apiStore.postRequest(
        '/api/rest/v1/etb/company/register',
        payload,
        options
      ),
    sendNewCompanyApplication: (payload, options) =>
      apiStore.postRequest(
        '/api/rest/v1/etb/company/application',
        payload,
        options
      ),
    orderTickets: (companyId, payload, options) => {
      pushTagEvent({
        event: 'ticket_order',
        type: payload.orderType,
      })

      return apiStore.postRequest(
        `/api/rest/v1/etb/purchase/order/${companyId}`,
        payload,
        options
      )
      // return apiStore.postRequest('/foo', payload, options)
    },
    requestActivationOfCommutingCalculator: async (companyId, options) => {
      return apiStore.postRequest(
        `/api/rest/v1/etb/email/commmutingCalculator/${companyId}`,
        undefined,
        options
      )
    },
    getBenefitStatistics: (companyId, endDate, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/company/${companyId}/statistics/benefits?end=${endDate}`,
        options
      ),
    getCurrentYearsAnnualBalances: (companyId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/benefit/statistics/grantedRange/${companyId}`,
        options
      ),
    getNextYearsAnnualBalance: (companyId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/benefit/grantedAmount/${companyId}`,
        options
      ),
    setNextYearsAnnualBalance: (companyId, payload, options) =>
      apiStore.putRequest(
        `/api/rest/v1/etb/benefit/grantedAmount/${companyId}`,
        payload,
        options
      ),
    deleteNextYearsAnnualBalance: (companyId, options) =>
      apiStore.deleteRequest(
        `/api/rest/v1/etb/benefit/grantedAmount/${companyId}`,
        options
      ),
    getBalanceStatus: (companyId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/benefit/balanceStatus/${companyId}`,
        options
      ),
    setBalanceStatus: (companyId, payload, options) =>
      apiStore.putRequest(
        `/api/rest/v1/etb/benefit/balanceStatus/${companyId}`,
        payload,
        options
      ),
    editEmployeeId: (companyId, userId, payload, options) =>
      apiStore.patchRequest(
        `/api/rest/v1/etb/company/${companyId}/beneficiary/${userId}`,
        payload,
        options
      ),
  },
  beneficiary: {
    getBeneficiaries: ({
      companyId,
      startIndex,
      sortBy,
      sortOrder,
      filters,
      count,
      options,
    }) => {
      const params = {
        inlinedLatestPurchases: 3,

        // Pagination
        startIndex,

        // Sorting
        ...(sortBy && sortOrder
          ? {
              sortBy,
              sortOrder,
            }
          : {}),

        // Filters
        ...(filters.benefitType
          ? { benefitType: filters.benefitType }
          : undefined),
        ...(filters.validity ? { validity: filters.validity } : undefined),
        ...(filters.invoiceId ? { invoiceId: filters.invoiceId } : undefined),
        ...(filters.annualBalanceMin
          ? { annualBalanceMin: filters.annualBalanceMin }
          : undefined),
        ...(filters.annualBalanceMax
          ? { annualBalanceMax: filters.annualBalanceMax }
          : undefined),
        ...(filters.searchText
          ? { searchText: filters.searchText }
          : undefined),
        ...(filters.isArchived
          ? { isArchived: filters.isArchived }
          : undefined),
        ...(filters.zone ? { zone: filters.zone } : undefined),
      }

      if (count) {
        params.count = count
      }

      const urlSearchParams = new URLSearchParams(params)

      return apiStore.getRequest(
        `/api/rest/v1/etb/company/${companyId}/beneficiary?${urlSearchParams.toString()}`,
        options
      )
    },
    getBeneficiary: (companyId, beneficiaryId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/company/${companyId}/beneficiary/${beneficiaryId}`,
        options
      ),
    getBenefits: (body, options) => {
      return apiStore.postRequest(
        '/api/rest/v1/etb/benefit/statistics',
        body,
        options
      )
    },
    updateBeneficiaries: (payload, options) =>
      apiStore.patchRequest(
        '/api/rest/v1/etb/benefit/batchEdit',
        payload,
        options
      ),
    updateBusinessTripBeneficiaries: (payload, options) =>
      apiStore.patchRequest(
        '/api/rest/v1/etb/benefit/batchEdit',
        payload,
        options
      ),
    archiveBeneficiary: (companyId, beneficiaryId, options) =>
      apiStore.postRequest(
        `/api/rest/v1/etb/company/${companyId}/beneficiary/${beneficiaryId}/archive`,
        undefined,
        options
      ),
    revokeInvites: (inviteIds, options) =>
      apiStore.postRequest(
        '/api/rest/v1/etb/invite/batchRevoke',
        { ids: inviteIds },
        options
      ),
    deleteCompanyAdmins: (companyId, ids, options) =>
      apiStore.postRequest(
        `/api/rest/v1/etb/company/${companyId}/admin/batchRevoke`,
        { ids },
        options
      ),
  },
  school: {
    getSchoolSeasons: (companyId, fetchInactive, options) => {
      const urlParams = new URLSearchParams({ active: !fetchInactive })
      return apiStore.getRequest(
        `/api/rest/v1/companies/${companyId}/school/seasons?${urlParams}`,
        options
      )
    },
    getSchoolSeasonById: (companyId, calendarId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/companies/${companyId}/school/seasons/${calendarId}`,
        options
      ),
    createSchoolSeason: (companyId, payload, options) =>
      apiStore.postRequest(
        `/api/rest/v1/companies/${companyId}/school/seasons`,
        payload,
        options
      ),
    sendBeneficiaryInvites: (companyId, payload, options) =>
      apiStore.postRequest(
        `/api/rest/v1/companies/${companyId}/school/beneficiaries`,
        payload,
        options
      ),
    updateSchoolSeason: (companyId, calendarId, options) =>
      apiStore.putRequest(
        `/api/rest/v1/companies/${companyId}/school/seasons/${calendarId}`,
        options
      ),
    getReports: (companyId, year, month, options) => {
      const filter = `year=${year}${month !== null ? `&month=${month}` : ''}`

      return apiStore.getRequest(
        `/api/rest/v1/companies/${companyId}/school/reports/granted-tickets?${filter}`,
        options
      )
    },
    getBeneficiaries: (companyId, { sortBy, ...params }) => {
      const urlParams = new URLSearchParams(params)
      sortBy?.split(',').forEach((sort) => urlParams.append('sortBy', sort))
      return apiStore.getRequest(
        `/api/rest/v1/companies/${companyId}/school/beneficiaries?${urlParams}`
      )
    },
    getBeneficiaryById: (companyId, beneficiaryId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/companies/${companyId}/school/beneficiaries/${beneficiaryId}`,
        options
      ),
    getBeneficiaryValidityDays: (
      companyId,
      beneficiaryId,
      year,
      month,
      options
    ) =>
      apiStore.getRequest(
        `/api/rest/v1/companies/${companyId}/school/beneficiaries/${beneficiaryId}/validity-days?${new URLSearchParams(
          { year, month }
        )}`,
        options
      ),
    updateBeneficiary: (companyId, beneficiaryId, payload, options) =>
      apiStore.putRequest(
        `/api/rest/v1/companies/${companyId}/school/beneficiaries/${beneficiaryId}`,
        payload,
        options
      ),
    deleteBeneficiary: (companyId, beneficiaryId, options) =>
      apiStore.deleteRequest(
        `/api/rest/v1//companies/${companyId}/school/beneficiaries/${beneficiaryId}`,
        options
      ),
    updateBeneficiaries: (companyId, payload, options) =>
      apiStore.patchRequest(
        `/api/rest/v1/companies/${companyId}/school/beneficiaries/batchEdit`,
        payload,
        options
      ),
  },
  products: {
    getProducts: (options, params) => {
      const { ticketType } = params
      const queryParams = new URLSearchParams()

      if (ticketType) {
        queryParams.append('ticketType', ticketType)
      }

      return apiStore.getRequest(
        `/api/rest/v1/etb/products?${queryParams}`,
        options
      )
    },
  },
  events: {
    getEvents: (companyId, startIndex, options) => {
      const params = `startIndex=${startIndex}&count=${defaultPageSize}&filter=companyId eq "${companyId}" and (type ne "COMPANY_EDIT" or type eq "COMPANY_EDIT" and aux.companies.changes.purchaseState pr)`

      return apiStore.getRequest(`/api/rest/v1/etb/event?${params}`, options)
    },
    getCompanyEvents: (companyId, startIndex, options) => {
      const filter = `startIndex=${startIndex}&count=${defaultPageSize}&filter=companyId eq "${companyId}"`

      return apiStore.getRequest(`/api/rest/v1/etb/event?${filter}`, options)
    },
    getBeneficiaryEvents: (
      companyId,
      beneficiaryId,
      isSchoolBeneficiary,
      startIndex,
      count,
      options
    ) => {
      const filter = `startIndex=${startIndex}&count=${count}&filter=companyId eq "${companyId}" and ${isSchoolBeneficiary ? 'aux.schoolBeneficiaries._id' : 'aux.benefits.userId'} eq "${beneficiaryId}"`

      return apiStore.getRequest(`/api/rest/v1/etb/event?${filter}`, options)
    },
    getSupervisorEvents: ({
      startIndex,
      sortBy,
      sortOrder,
      searchText,
      eventTypes,
      options,
    }) => {
      const urlSearchParams = new URLSearchParams({
        // Pagination
        startIndex,
        count: defaultPageSize,

        // Sorting
        ...(sortBy && sortOrder
          ? {
              sortBy,
              sortOrder,
            }
          : {}),

        // Search
        ...(searchText ? { searchText } : {}),

        // Event Type Search
        ...(eventTypes
          ? { eventTypes: eventTypes.map(($) => $.value).join(',') }
          : {}),
      })

      return apiStore.getRequest(
        `/api/rest/v1/etb/event?${urlSearchParams.toString()}`,
        options
      )
    },
  },
  banners: {
    getBanners: (language, options) =>
      apiStore.getRequest(
        `/api/rest/v1/content/banners?language=${language}`,
        options
      ),
  },
  tos: {
    acceptTermsOfService: (companyId, options) =>
      apiStore.postRequest(
        `/api/rest/v1/etb/company/${companyId}/tos/accept`,
        undefined,
        options
      ),
  },
  invoiceOption: {
    getInvoiceOptions: (companyId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/company/${companyId}/invoiceOptions`,
        options
      ),
    getInvoiceOption: (companyId, invoiceId, options) =>
      apiStore.getRequest(
        `/api/rest/v1/etb/company/${companyId}/invoiceOptions/${invoiceId}`,
        options
      ),
    createInvoiceOption: (companyId, payload, options) =>
      apiStore.postRequest(
        `/api/rest/v1/etb/company/${companyId}/invoiceOptions`,
        payload,
        options
      ),
    updateInvoiceOption: (companyId, invoiceId, payload, options) =>
      apiStore.putRequest(
        `/api/rest/v1/etb/company/${companyId}/invoiceOptions/${invoiceId}`,
        payload,
        options
      ),
    deleteInvoiceOption: (companyId, id, options) =>
      apiStore.deleteRequest(
        `/api/rest/v1/etb/company/${companyId}/invoiceOptions/${id}`,
        undefined,
        options
      ),
  },
  purchase: {
    getPurchasesForReports: ({
      pageNumberAsIndex,
      companyId,
      fromDate,
      toDate,
      options,
    }) => {
      const urlSearchParams = new URLSearchParams({
        startIndex: pageNumberAsIndex * defaultPageSize,
        count: defaultPageSize,
        filter: `companyId eq "${companyId}" and (state eq "COMMITTED" or state eq "TRANSFERRED") and time ge "${fromDate.toISOString()}" and time le "${toDate.toISOString()}"`,
      })

      return apiStore.getRequest(
        `/api/rest/v1/etb/purchase?${urlSearchParams.toString()}`,
        options
      )
    },
    getPurchases: ({
      startIndex,
      pageSize = defaultPageSize,
      companyId,
      beneficiaryId,
      options,
    }) => {
      const urlSearchParams = new URLSearchParams({
        startIndex,
        count: pageSize,
        filter: `companyId eq "${companyId}" and (state eq "PROJECTED" or state eq "TRANSFERRED" or state eq "COMMITTED") and userId eq "${beneficiaryId}"`,
      })

      return apiStore.getRequest(
        `/api/rest/v1/etb/purchase?${urlSearchParams.toString()}`,
        options
      )
    },
    getUpcomingPurchases: (companyId, beneficiaryId, options) => {
      const urlSearchParams = new URLSearchParams({
        companyId,
        beneficiaryId,
        filter: 'state eq "PROJECTED"',
      })

      return apiStore.getRequest(
        `/api/rest/v1/etb/purchase/upcoming?${urlSearchParams.toString()}`,
        options
      )
    },
    getPurchaseStatistics: (companyId, fromDate, toDate, options) => {
      const params = `begin=${formatDateFn(
        fromDate,
        YYYY_MM_DD
      )}&end=${formatDateFn(toDate, YYYY_MM_DD)}`

      return apiStore.getRequest(
        `/api/rest/v1/etb/company/${companyId}/statistics/purchases?${params}`,
        options
      )
    },
  },
  digitransit: {
    addressSearch: (text, options) => {
      return apiStore.getRequest(
        `/api/rest/digitransit/geocoding/v1/search?text=${text}`,
        options
      )
    },
  },
  test: {
    captureEvent: (options) =>
      apiStore.getRequest('/api/test/capture-event', options),
    throwError: (options) =>
      apiStore.getRequest('/api/test/throw-error', options),
    slowRequest: (options) =>
      apiStore.getRequest('/api/test/slow-request', options),
    slowerRequest: (options) =>
      apiStore.getRequest('/api/test/slower-request', options),
  },
})

export default createEndpoints
