import classnames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg'
import { Link } from '../../modules/routes/components/Link'
import './Button.scss'
import Spinner from './Spinner'

export const Button = forwardRef(
  (
    {
      children,
      secondary = false,
      transparent = false,
      warning = false,
      small = false,
      compact = false,
      bold = false,
      secondaryWarning = false,
      loading = false,
      className,
      style,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      type="button"
      className={classnames(
        'common__button',
        {
          'common__button--secondary': secondary,
          'common__button--transparent': transparent,
          'common__button--warning': warning,
          'common__button--secondary-warning': secondaryWarning,
          'common__button--small': small,
          'common__button--compact': compact,
          'common__button--bold': bold,
        },
        className
      )}
      style={style}
      {...props}
    >
      {loading ? <Spinner size={small ? 20 : 24} /> : <span>{children}</span>}
    </button>
  )
)

Button.displayName = 'Button'

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondary: PropTypes.bool,
  transparent: PropTypes.bool,
  warning: PropTypes.bool,
  small: PropTypes.bool,
  compact: PropTypes.bool,
  bold: PropTypes.bool,
  secondaryWarning: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const LinkButton = forwardRef(
  (
    {
      children,
      secondary = false,
      transparent = false,
      warning = false,
      small = false,
      compact = false,
      bold = false,
      route,
      params,
      query,
      disabled = false,
      className,
      style,
      ...props
    },
    ref
  ) => (
    <Link
      ref={ref}
      route={route}
      params={params}
      query={query}
      className={classnames(
        'common__button',
        {
          'common__button--secondary': secondary,
          'common__button--transparent': transparent,
          'common__button--warning': warning,
          'common__button--disabled': disabled,
          'common__button--small': small,
          'common__button--compact': compact,
          'common__button--bold': bold,
        },
        className
      )}
      style={style}
      {...props}
    >
      <span>{children}</span>
    </Link>
  )
)

LinkButton.displayName = 'LinkButton'

LinkButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  secondary: PropTypes.bool,
  transparent: PropTypes.bool,
  warning: PropTypes.bool,
  small: PropTypes.bool,
  compact: PropTypes.bool,
  bold: PropTypes.bool,
  route: PropTypes.object.isRequired,
  params: PropTypes.object,
  query: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const ExternalLinkButton = forwardRef(
  (
    {
      children,
      secondary = false,
      transparent = false,
      warning = false,
      small = false,
      compact = false,
      bold = false,
      href,
      disabled = false,
      className,
      style,
      withIcon,
      ...props
    },
    ref
  ) => {
    const disableButton = disabled || !href
    const Element = disableButton ? 'div' : 'a'

    return (
      <Element
        ref={ref}
        href={disableButton ? undefined : href}
        className={classnames(
          'common__button',
          {
            'common__button--secondary': secondary,
            'common__button--transparent': transparent,
            'common__button--warning': warning,
            'common__button--small': small,
            'common__button--compact': compact,
            'common__button--bold': bold,
          },
          className
        )}
        style={style}
        disabled={disableButton}
        {...props}
      >
        <span>{children}</span>
        {withIcon && <LinkIcon className="ml-xs size-sm" />}
      </Element>
    )
  }
)

ExternalLinkButton.displayName = 'ExternalLinkButton'

ExternalLinkButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  secondary: PropTypes.bool,
  transparent: PropTypes.bool,
  warning: PropTypes.bool,
  small: PropTypes.bool,
  compact: PropTypes.bool,
  bold: PropTypes.bool,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  withIcon: PropTypes.bool,
}

export const LinkTextButton = ({
  children,
  onClick,
  className,
  style,
  ...props
}) => (
  <span
    onClick={onClick}
    className={classnames('common__link-text-button', className)}
    style={style}
    {...props}
  >
    {children}
  </span>
)

LinkTextButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const ButtonGroup = ({
  children,
  centered,
  alignRight,
  className,
  style,
}) => (
  <div
    className={classnames(
      'common__button-group',
      { 'common__button-group--centered': centered },
      { 'common__button-group--align-right': alignRight },
      className
    )}
    style={style}
  >
    {children}
  </div>
)

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
  alignRight: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const IconButton = ({ url, onClick, className, icon, alt }) => (
  <div className={classnames('common__icon-button', className)}>
    {onClick && (
      <button className="button-container" onClick={onClick} type="button">
        <img className="img-container" src={icon} alt={alt} />
      </button>
    )}
    {url && (
      <a className="link-container" href={url}>
        <img className="img-container" src={icon} />
      </a>
    )}
  </div>
)

IconButton.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.node,
  alt: PropTypes.string,
}
